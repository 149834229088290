@import "~bootstrap/scss/bootstrap-reboot.scss";
//@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/spinners";

$primary: #6FC7B6;
$secondary: #004A5D;

body {
  background-color: #fff;
  user-select: none;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;

  .logo {
    pointer-events: none;
    width: 70%;
    height: auto;
    max-width: 320px;
    margin-bottom: 30px;
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .form-control {
      display: inline-block;
      &:focus {
        //border: none !important;
        box-shadow: none !important;
      }
      &:first-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-right-width: 0;
        width: 64px;
        padding-right: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-left-width: 0;
        padding-left: 0;
      }
    }
  }

  .form-control {
    height: auto;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 50px;
    background-color: #F6F6F6;
  }
  #smsCode {
    max-width: 240px;
    font-size: 20px;
  }

  .btn-outline-secondary {
    color: $secondary;
    &:hover {
      color: white;
      background-color: $secondary;
    }
  }
  .btn-primary {
    background-color: $primary;
    border-radius: 50px;
    .spinner-border {
      width: 1.6rem;
      height: 1.6rem;
      border-width: .15em;
    }
    &:active:not(:disabled) {
      background-color: $primary !important;
      opacity: .8;
    }
  }
  .spinner-border.large {
    width: 3rem;
    height: 3rem;
    border-width: .25em;
    color: $secondary;
  }


  .errors {
    font-size: 1rem;
    color: red;
  }
}

/*
.content {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;

  .logo {
    pointer-events: none;
    width: 50%;
    height: auto;
    max-width: 320px;
    margin-bottom: 30px;
  }


}*/
